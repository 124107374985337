<template>
  <div>
    <v-card class="pa-3">
      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        :height="1"
        class="pa-0 ma-0"
      /> 
    </v-card>
    <v-card v-if="userData && userData.id" class="pa-3">
      <v-layout row>
        <v-flex xs3>
          <div class="mb-3">
            <v-avatar v-if="userData.image" size="65px">
              <img :src="userData.image" alt="">
            </v-avatar>
            <v-avatar v-else size="65px">
              <v-icon class="no-avatar-icon">
                mdi-account
              </v-icon>
            </v-avatar>
          </div>

          <div
            class="break headline text--darken-1 mb-1"
            v-text="userData.name"
          ></div>
          <div
            class="break subheading text--darken-1 mb-3"
            v-text="userData.username"
          ></div>

          <div class="headline grey--text mb-3">
            Karma:
            <span class="bold white--text" v-text="userData.karma"></span>
          </div>

          <div class="mb-4">
            <div class="title grey--text mb-2">
              General info
            </div>

            <v-list>
              <div class="mb-1">
                <v-icon class="blue-grey--text mr-2">
                  mode_comment
                </v-icon>
                <b v-text="userData.comments_count"></b> Comments
              </div>
              <div class="mb-1">
                <v-icon class="blue-grey--text mr-2">
                  photo
                </v-icon>
                <b v-text="userData.posts_count"></b> Posts
              </div>
              <div class="mb-1">
                <v-icon class="blue-grey--text mr-2">
                  thumb_up
                </v-icon>
                <b
                  class="teal--text"
                  v-text="userData.user_set_like_up_votes_count"
                ></b>
                Upvotes
              </div>
              <div class="mb-1">
                <v-icon class="blue-grey--text mr-2">
                  thumb_down
                </v-icon>
                <b
                  class="red--text"
                  v-text="userData.user_set_like_down_votes_count"
                ></b>
                Downvotes
              </div>
            </v-list>
          </div>
        </v-flex>

        <v-flex xs9>
          <v-data-table
            :items="reportList"
            :loading="isLoading"
            class="elevation-1"
            hide-actions
            hide-headers
          >
            <template slot="items" slot-scope="props">
              <td>
                Has been reported for
                <b class="orange--text" v-text="props.item.reason"></b>
                by
                <router-link
                  :to="
                    `/moderation/user_list/user/${props.item.who_sent_report.id}`
                  "
                  class="blue-grey--text bold link-hover"
                >
                  {{ props.item.who_sent_report.username }}
                </router-link>
              </td>
              <td class="text-xs-right">
                <div
                  class="date"
                  v-text="dateFormat(props.item.created_at)"
                ></div>
              </td>
            </template>
          </v-data-table>

          <div class="text-xs-center mt-4">
            <div class="text-xs-center">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                @input="refreshPagination"
              />
            </div>
          </div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <template v-if="getUserInfoAndPermissions.role === 'Admin'">
            <v-btn
              v-if="!userBlocked"
              color="error"
              class="ma-0"
              @click="openBlockUserModal(userData.id)"
            >
              Block user
            </v-btn>
            <v-btn
              v-else
              :loading="isLoadingBlock"
              color="success"
              class="ma-0"
              @click="unlockedUser(userData.id)"
            >
              Unblock User
            </v-btn>
            <block-user-modal
              v-model="blockUserModal"
              :user-id="userId"
              :reason="reason"
              @deleteCurrentUser="deleteCurrentUser"
            />
          </template>
        </v-flex>

        <v-flex xs9 class="text-xs-right">
          <v-btn flat class="blue-grey ma-0" @click="returnBack">
            <v-icon left dark class="font-size-20">
              arrow_back
            </v-icon>
            Back
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {
  findUserByIdRequest,
  userReportsRequest,
  userUnbanned
} from "../../../../../api/user"
import { fetchData } from "../../../../../utils/response"
import BlockUserModal from "@/components/Moderation/UserList/BlockUserModal"
import { getReasons } from "../../../../../api/reason"
import moment from "moment"
import { log } from "../../../../../utils/helpers"
export default {
  name: "ViewReportedUserPage",
  components: {
    BlockUserModal
  },
  data() {
    return {
      userBlocked: false,
      blockUserModal: false,
      userId: null,
      isLoading: false,
      isLoadingBlock: false,
      userData: {},
      reason: [],
      prevRoute: null,
      reportList: [],
      total: 0,
      pagination: {
        page: 1,
        rowsPerPage: 10
      }
    }
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"]),
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  mounted() {
    this.getReasonBlockUser()
  },
  created() {
    this.fetchUserData()
  },
  methods: {
    deleteCurrentUser() {
      this.userBlocked = true
      this.$notificationHandleResponseError("The user is successfully blocked")
    },
    unlockedUser(id) {
      this.isLoadingBlock = true
      log(id)
      userUnbanned(id)
        .then(() => {
          this.$notificationShow("The user is successfully unlocked")
          this.userBlocked = false
          this.isLoadingBlock = false
        })
        .catch(this.$notificationHandleResponseError)
    },
    refreshPagination() {
      this.getUserReportsRequest()
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    openBlockUserModal(id) {
      this.userId = id
      this.blockUserModal = true
    },
    async getReasonBlockUser() {
      this.reason = await getReasons()
        .then(response => {
          return response.data.data
        })
        .then(data =>
          data.map(reason => {
            return reason
          })
        )
        .catch(this.$notificationHandleResponseError)
    },
    async fetchUserData() {
      this.isLoading = true
      await findUserByIdRequest(this.$route.params.id)
        .then(fetchData)
        .then(user => {
          this.userData = user
          this.userBlocked = user.banned
        })
        .catch(this.$notificationHandleResponseError)
      await this.getUserReportsRequest().finally(
        () => (this.isLoading = false)
      )
    },
    async getUserReportsRequest() {
      this.isLoading = true
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage
      }
      this.reportList = await userReportsRequest(this.$route.params.id, params)
        .then(response => {
          this.total = response.data.meta.total
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(report => {
            return report
          })
        )
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    returnBack() {
      if (this.prevRoute && this.prevRoute.fullPath === "/") {
        this.$router.push({ name: "reported_users" })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.date {
  min-width: 120px;
}
</style>
